.p-button:focus {
    outline: none !important;
    box-shadow: none !important;
}

.p-dialog .p-dialog-header-icon{
  display: none !important;
}
  
.p-dialog-content{
  text-align: center;
}

.dialog-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}

p{
  font-size: initial;
}

.spinner {
    position: absolute !important;
}

.p-fileupload-buttonbar{
  display: none;
}
.p-badge-warning{
  display: none;
}
.p-progressbar{
  display: none;
}
.p-fileupload-row{
  text-align: left;
}
.p-fileupload .p-fileupload-content{
  border-radius: 6px !important;
}
.p-toast-message-text{
  text-align: left;
}

@media screen and (max-width: 821px) {
  .p-dialog {
    width: 100% !important;
  }
}

@media (max-width: 1060px) {
  .p-dialog {
    width: 60vw !important;
  }
}

@media (max-width: 700px) {
  .p-dialog {
    width: 80vw !important;
  }
}

@media (max-width: 577px) {
  .p-dialog {
    width: 90vw !important;
  }
}

/* less than 350 */
@media (max-width: 350px) {
  .input-p{
    width: 240px !important
  }
  .pi-camera{
    font-size: 1.5rem;
  }
  .pi-upload{
    font-size: 0.6rem;
  }
  .message-inner{
    font-size: 0.6rem;
    height: 1.9rem !important;
  }
  .message-inner2{
    font-size: 0.9rem;
  }
}

.pi-upload{
  margin-left: 6px !important;
}

.p-buttonx {
  color: #4338CA;
  background: transparent;
  border: transparent;
  margin: 0;
  display: inline-flex;
  cursor: pointer;
  user-select: none;
  align-items: center;
  vertical-align: bottom;
  text-align: center;
  overflow: hidden;
  position: relative;
}

.drag-over {
  border: 2px dashed #4338CA;
  background-color: rgba(67, 56, 202, 0.1);
  color: #4338CA;
  transition: all 0.3s ease-in-out;
  transform: scale(1.05);
}

.p-button-label{
  margin-right: 0.2rem;
}