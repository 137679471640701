.main-class-nui{
    /* padding: 60px 500px;
    padding-right: 450px; */
    /* padding: 60px 30vw;
    padding-right: 30vw; 
    min-width: 500px */
    width: 400px;
}
.to-heading-nui{
    /* width: 300px; */
    font-size: 20px;
}
.to-heading2-nui{
    /* width: 300px; */
    margin-top: 50px;
    margin-bottom: 50px;
    font-size: 15px;
    color: gray;
}
.to-heading3-nui{
    margin-top: 10px;
    margin-bottom: 70px;    
    font-size: 15px;
    color: blue;
    display: inline-block;
}
.continue-btn{
    width: 300px;
    width: 100%;
}
.to-heading4-nui{
    margin-top: 15px;
    margin-bottom: 0px;
    font-size: 15px;
}
.to-heading5-nui{
    margin-top: 15px;
    margin-bottom: 0px;
    font-size: 13px;

    /* padding-left: 100px; */
    /* text-align: center; */
    color: #575757;
    margin-left: 100px;
}
.Partner-heading1-nui{
    width: 100%;
    text-align: center;
    margin-bottom: 50px;
}
.Partner-nui{
    width: 300px;
    display: inline-block;
    margin: 50px;
    position: relative;
}
.Partner-class-nui{
    margin-top: 60px;
    position: relative;
}
.categories-container{
    /* display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 0px 20px; */

    display: grid;
    grid-template-columns: repeat(auto-fill, 310px);
    justify-content: space-around;
    grid-gap: 0px 20px;
}
.category{
    display: inline-block;
    height: 280px;
}
.dropdown-background{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    /* background-color: #f9f9f9; */
}
.dropdown{
    display: inline-block;
    /* position: static; */
    position: relative;
    z-index: 1;
    cursor: pointer;
}
.dropdown-p3{
    position: static;
}
.dropdown-div{
    width: 300px;
    /* height: 35px; */
    padding: 10px 15px;
    background-color: white;
    /* border: 1px solid #ced4da; */
    border: 1px solid #838383;
    border-radius: .25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dropdown-options{
    width: 300px;
    position: absolute;
    /* border-left: 2px solid rgb(178, 178, 178);
    border-right: 2px solid rgb(178, 178, 178); */
    /* border: 1px solid #ced4da;  */
    border: 1px solid #838383; 
    border-radius: .25rem;
    /* background-color: #ffffff; */
    background-color: rgb(243, 243, 243);
    display: inline-block;
    max-height: 188px;
    overflow: hidden auto;
}
.dropdown-options-partner{
    width: 300px;
    /* height: 30px; */
    padding: 8px 15px;
    /* border-bottom: 1px solid #ced4da; */
    border-bottom: 1px solid #838383;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
}
.dropdown-options-partner:last-child{
    border-bottom: none;
}

.arrow {
    border: solid #000000bf;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
}
.arrow-option{
    border-color: #878787;
}
.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}
.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}
.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}
.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}
.btn-div{
    display: flex;
    justify-content: end;
}
.next-btn{
    width: 250px;
}
/* .partner-page{
    position: relative;
    height: 100%;
} */
.btn-div-p{
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 50px;
    width: 90%;
    align-items: center;
}
.title-p{
    text-align: center;
}
.info-p{
    margin-top: 20px;
    margin-bottom: 100px;
}
.info-inner-p{
    margin-top: 30px;
    margin-left: 40px;
}
.list-p{
    margin-top: 20px;
}
.form-p{
    margin-top: 40px;
}
.scl{
    display: inline-block;
    width: 285px;
}
.scl-blibli{
    width: 360px;
}
.input-p{
    display: inline-block;
    width: 350px;
}
.back-btn-p{
    width: 150px;
    /* background-color:  none !important; */
}
.info-s{
    width: 100%;
    text-align: left;
    margin: 10px 0px;
}
.info-s1{
    width: 100%;
    text-align: center;
}
.namste-image{
    height: 150px;
}
.image-s1{
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
}
.xyzq{
    margin-bottom: 15px;
}
.xyzq span{
    margin-left: 20px;
}
.partner-page{
    margin-top: 60px;
}




.topnav .search-container {
    float: right;
  }
  
  .topnav input[type=text] {
    padding: 6px;
    margin-top: 8px;
    font-size: 17px;
    border: none;
  }
  
  .topnav .search-container button {
    float: right;
    padding: 6px 10px;
    margin-top: 8px;
    margin-right: 16px;
    background: #ddd;
    font-size: 17px;
    border: none;
    cursor: pointer;
  }
  
  .topnav .search-container button:hover {
    background: #ccc;
  }
.search-container{
    position: absolute;
    right: 0px;
    top: 0px
}  

.search-container-p3{
    /* left: 300px;
    width: 300px; */
    position: static;
    position: absolute;
    right: 0px;
    top: -50px;
    /* left: 43%; */
}
.search-container-div{
    position: relative;
    /* width: ; */
}


.container{
    margin-left: 0px;
}
.pwc-logo{
    position: absolute;
    left: 20px;
    top: 20px;
    height: 60px;

    max-width: 120px;
    max-height: 60px;
    height: auto;
}
.success-page{
    padding: 60px 50px;
}
.flash-message-container{
    /* height: 80px; */
    width: 700px;
    /* width: max-content; */
    position: relative;
    background-color: rgb(255, 255, 255);
    /* border: 10px solid green; */
    border-top: 20px solid none;
    border-left: 20px solid green;
    border-right: 20px solid none;
    border-bottom: 20px solid none;
    border-radius: 10px;
    z-index: 10;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    /* vertical-align: middle; */

    padding-right: 95px;
    padding-left: 66px;
    padding-top: 10px;
    padding-bottom: 10px;
    /* min-width: 374px; */
    min-height: 80px;
    
    -webkit-box-shadow: 5px 6px 15px 0px rgba(184,184,184,1);
    -moz-box-shadow: 5px 6px 15px 0px rgba(184,184,184,1);
    box-shadow: 5px 6px 15px 0px rgba(184,184,184,1);
}

.flash-message-icon-div {
    position: absolute;
    left: 0px;
    top: 50%;
    margin-top: -15px;
  }

.flash-message-close{
    padding: 26px 20px;
    border-left: 1px solid gray;
    color: gray;
    height: 100%;
    position: absolute;
    right: 0px;
    /* margin-left: 300px; */
    cursor: pointer;
    font-weight: bold;

    display: flex !important;
    align-items: center;
    justify-content: center;
}
.flash-message-container div{
    display: inline-block;
    vertical-align: middle;
}
.flash-message-body{
    max-height: 210px;
    overflow-y: auto;
}
.flash-message-success-icon{
    margin: 0px 20px;
    height: 25px;
    width: 25px;
}
.flash-message-title{
    font-size: 23px;
    margin-top: 0px;
    color: black;
}
.flash-message-info{
    color: gray;
}
.flash-message-body{
    line-height: 22px;
    /* height: 70px; */
}
.flash-message-outer{
    position: fixed;
    /* top: 200px; */
    top: 150px;
    left: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 1000;
}
.flash-message-success{
    border-left-color: red;
}
.flash-message-info{
    border-left-color: blue;
}
.flash-message-background{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: #00000042;
}

.partner-info-icon{
    position: relative;
    bottom: 10px;
    left: 2px;
}
.partner-info-icon-message{
    /* display: inline-block; */
    display: none;
    /* background-color: #c9c9c9; */
    background-color: #f3f3f3;
    border: 1px solid gray;
    font-size: 12px;
    position: fixed;
    z-index: 20;
    /* height: 50px; */
    max-width: 350px;
    padding: 5px 10px;
    border-radius: 10px;
    font-size: 14px;
    /* color: black; */
    font-weight: normal;
}
.search-partner{
    padding: 8px 15px;
    /* border-bottom: 1px solid #ced4da; */
    border-bottom: 1px solid #838383;
    background-color: rgb(243, 243, 243);
    cursor: pointer;
    width: 290px;
}
.search-partners-container{
    display: none;
    z-index: 2;
    max-height: 330px;
    width: 290px;
    overflow: hidden auto;
}
.searchbar-input-p3{
    width: 270px;
}
.search-container{
    /* position: static; */
    z-index: 15;
    width: fit-content;
}
.searchbar-input{
    padding: 5px 10px;
    background-image: url('https://www.w3schools.com/css/searchicon.png');
    background-position: 260px 8px;
    background-repeat: no-repeat;
    /* border: 1px solid #ddd; */
    border: 1px solid #838383;
    border-radius: 3px;
    width: 290px;
}
.search-partners-background{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
}


.category-p2{
    display: inline-block;
    height: 280px;
}
.dropdown-div-p2{
    /* width: 900px; */
    width: 60vw;
    /* height: 35px; */
    padding: 10px 15px;
    background-color: white !important;
    /* border: 1px solid #ced4da; */
    border: 1px solid #838383;
    border-radius: .25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dropdown-options-p2{
    /* width: 900px; */
    width: 60vw;
    position: absolute;
    /* border-left: 2px solid rgb(178, 178, 178);
    border-right: 2px solid rgb(178, 178, 178); */
    /* border: 1px solid #ced4da;  */
    border: 1px solid #838383;
    border-radius: .25rem;
    /* background-color: #ffffff; */
    background-color: rgb(243, 243, 243);
    display: inline-block;
    max-height: 188px;
    overflow: hidden auto;
}
.dropdown-options-p2-selected-p{
    background-color: #ffffff; 
}
.dropdown-options-partner-p2{
    /* width: 900px; */
    width: 60vw;
    /* height: 30px; */
    padding: 8px 15px;
    /* border-bottom: 1px solid #ced4da; */
    border-bottom: 1px solid #838383;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dropdown-options-partner-p2:last-child{
    border-bottom: none;
}

.dropdown-options-partner-image {
    height: 30px;
    margin-left: 8px;
    margin-right: 10px;
  }
.dropdown-options-partner-category{
    font-style: italic;
    font-size: 14px;
}
.dropdown-options-partner-remove{
    font-size: 14px;
    margin: 0px 10px;
}
.Partner-heading1-nui-p2{
    width: 100%;
    text-align: center;
    margin-bottom: 5px;
}

.category-p3{
    display: inline-block;
    /* height: 280px; */
}
/* .div-partner-p3{

} */
.category-p3-selected{
    display: inline-block;
    margin: 10px 0px;
    /* height: 280px; */
}

.dropdown-div-p3{
    width: 400px;
    /* height: 35px; */
    padding: 10px 15px;
    /* background-color: none !important; */
    /* border: 1px solid #ced4da; */
    border: 1px solid #838383;
    border-radius: .25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dropdown-options-p3{
    /* width: 400px; */
    width: 52vw;
    position: absolute;
    /* border-left: 2px solid rgb(178, 178, 178);
    border-right: 2px solid rgb(178, 178, 178); */
    /* border: 1px solid #ced4da;  */
    border: 1px solid #838383;
    border-radius: .25rem;
    /* background-color: #ffffff; */
    background-color: rgb(243, 243, 243);
    display: inline-block;
    max-height: 188px;
    overflow: hidden auto;
    z-index: 15;
}

.dropdown-options-partner-p3{
    /* width: 400px; */
    width: 52vw;
    /* height: 30px; */
    padding: 8px 15px;
    /* border-bottom: 1px solid #ced4da; */
    border-bottom: 1px solid #838383;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dropdown-options-partner-p3:last-child{
    border-bottom: none;
}

.selected-partner-p3{
    /* background-color: rgb(223, 223, 223); */
    width: 52vw;
    height: 45.6px;
}

.accordion-item{
    margin: 40px 0px;
    border-top: 1px solid rgba(0,0,0,.125) !important;
    background-color: none !important;
}

.accordion{
    margin-top: 120px;
}
.accordion-body{
    padding-top: 25px;
    padding-bottom: 45px;
}
.category-p3-selected-no{
    margin-right: 40px;
    /* font-weight: bold; */
}
.partner-remove-p3{
    display: inline-block;
    position: absolute;
    cursor: pointer;
    right: 40px;
    height: 100%;
    padding: 20px 0px;
}
.accordion-body-heading-p3{
    font-size: 17px;
}

/* .accordion-header{ */
    /* border: 1px solid #ced4da; */
    /* border: 1px solid #838383; */
    /* border-radius: 5px; */
/* } */
/* .accordion-header:nth-child(even){
    background-color: #9a0000;
} */
.accordion-button{
    border-radius: 5px !important;
    border: 1px solid #838383;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    /* height: 45.6px; */
}
.accordion-button.collapsed{
    /* border: 5px solid #838383; */
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}
.accordion-button:not(.collapsed) {
    /* background-color: #e7f1ff !important; */
    background-color: #bcb4d9 !important;
    background-image: none !important;
    /* background-image: url('../components/images/images/chevron-up.svg'); */
    border: 1px solid #1c1b4b;
}
.accordion-item{
    border: none !important;
    background-color: rgba(255, 255, 255, 0) !important;
}
.accordion-collapse{
    /* border: 1px solid #ced4da; */
    border: 1px solid #1c1b4b;
    border-radius: 5px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-top: none !important;
    /* margin-top: 40px; */
    background-color: white;
}
.accordion-button:focus {
    z-index: 3;
    border-color: #1c1b4b;
    outline: 0;
    box-shadow: 0 0 0 .25rem #1c1b4b47;
    box-shadow: none !important;
    /* background-color: #bcb4d9; */
    color: #1c1b4b;
  }
  .accordion-button:focus-visible{
    border-color: #1c1b4b;
  }
  .accordion-button:not(.collapsed) {
    color: #1c1b4b;
  }
  .searchbar-input:focus-visible {
    border-color: #1c1b4b;
    outline: 0;
    box-shadow: 0 0 0 .25rem #1c1b4b47;
  }
  .accordion-button:not(.collapsed)::after {
    background-image: url('../components/images/images/chevron-down.svg');
  }
  #root{
    min-height: 100vh;
    background-image:  url('../components/images/images/home-background.png'), url("../components/images/images/background-gradient-1.png");
    background-position: right top, 50% 80%;
    background-size: auto 100vh, cover;
    background-repeat: repeat-y, no-repeat;
    background-attachment: fixed, fixed;

    /* background-image: url('../components/images/images/home-background.png');
    background-position: right top;
    background-repeat:repeat-y;
    background-size: auto;
    background-attachment: scroll; */
    /* background-color: white; */
    /* background-repeat: space; */
    /* background-size: 100% auto;
    background-size: auto 100vh; */
    /* background-size: contain; */

    
  }
.category-connected-p2{
    display: block;
    margin-top: 20px;
}
/* .category-connect-p2{
    border-bottom: 2px solid gray;
    width: 100%;
} */
.home-click{
    cursor: pointer;
}
.dropdown-options-p2-long{
    max-height: 423px;
}


.google-translate{
    position: absolute;
    top: 20px;
    right: 30px;
    /* height: 25px;
    width: 68px; */
    /* border: 1px solid #626262; */
    /* border: 1px solid #1c1b4b;
    border-radius: 5px; */
    /* background-color: #c1c1c1; */
    /* background-color: #ffffff; */
    cursor: pointer;
    box-sizing: border-box;
    border-collapse: collapse;
    /* overflow: hidden; */
    /* display: flex; */
}
.google-translate-en{
    position: relative;
    left: 2px;
    
}
.google-translate-btn{
    /* height: 100%; */
    /* height: 25px; */
    width: 33px;
    padding: 2px 8px;
    border: 1px solid #1c1b4b;
    border-radius: 5px;
    /* top: 0px; */
    /* width: 40px; */
    background-color: #ffffff;
    border-radius: 2px;
    display: inline-block;
    border-collapse: collapse;
    box-sizing: border-box;
    font-size: 14px;
    z-index: 1;
    position: relative;
    
} 
.google-translate-btn-selected{
    /* background-color: #ffffff; */
    background-color: #bcb4d9;
    /* outline: 1px solid #626262; */
    /* outline: 1px solid #1c1b4b; */
    border: 1px solid #1c1b4b;
    z-index: 2;
    /* position: relative;
    top: -1px; */
    /* right: -1px; */
}
.main-conatiner{
    display: flex;
    justify-content: center;
    margin-top: 70px;
}
.search-container-div{
    position: unset;
}


.categories-mobile{
    overflow-x: scroll;
    /* height: 50px; */
    /* border: 1px solid gray; */
    display: flex;
    padding: 15px 10px;
    margin: 0px -10px;
}
.categories-mobile-category{
    display: inline-block;
    padding: 5px 8px;
    border: 2px solid rgba(128, 128, 128, 0);
    cursor: pointer;
    white-space: nowrap;
}
.categories-mobile-category-selected{
    /* border: 2px solid rgb(90, 90, 90); */
    border: 2px solid #1c1b4b;
    border-radius: 5px;
    font-weight: bold;
    margin: 0px 5px;
}
.dropdown-options-p2-mobile{
    margin-top: 20px;
    align-items: center;
    /* border: 1px solid #ced4da; */
    border: 1px solid #838383;
    border-radius: 5px;
    background-color: #ffffff;
    /* background-color: rgb(243, 243, 243); */
    /* display: flex;
    flex-direction: column; */
    display: inline-block;
    /* overflow: hidden; */
}
.option-div-mobile{
    text-align: center;
}

.menu-icon{
    display: none;
}
.menu-icon-row{
    width: 35px;
    height: 5px;
    background-color: rgb(67, 67, 67);
    margin: 6px 0;
}
.categories-container-p2-mobile{
    display: none;
}

.Partner-heading1-nui-p3{
    display: none;
}
.back-btn-p-m{
    display: none;
}
.fp-loader{
    position: static !important;
}
.fp-container{
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
}
.back-btn-pp{
    cursor: pointer;
    font-size: 18px;
    text-decoration: underline;
}
.search-partner-category{
    font-size: 12px;
    /* color: #5a5a5a; */
}
.tokopedia-prefix{
    position: absolute;
    margin-top: 7px;
    font-size: 15px;
    padding-right: 10px !important;
    padding-left: 10px !important;
}
.prefixT input{
    padding-left: 210px;
}
.wrong-token{
    font-size: 25px;
    text-align: center;
}
.beta-div{
    font-size: 12px;
    border: 1px solid black;
    position: relative;
    top: -9px;
    left: 7px;
    padding: 1px 3px;
}
.scrollbar-mobile{
    
    height: 10px;
    width: 10px;
    /* border: 1px solid gray; */
    border: 1px solid #1c1b4b;
    border-radius: 5px;
}
.scrollbar-mobile-div{
    display: flex;
    justify-content: center;
    gap: 0px 5px
}
.scrollbar-mobile-selected{
    /* background-color: #5a5a5a; */
    background-color: #6e68af;
    /* background-color: #BCB4D9; */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.categories-mobile-p3::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.categories-mobile-p3 {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

  .categories-mobile-p2::-webkit-scrollbar {
    /* width: 5vw; */
    height: 10px;
  }
  .categories-mobile-p2::-webkit-scrollbar-track {
    /* border-bottom-left-radius: 3px;
    border-top-left-radius: 3px; */
    border-radius: 5px;
    /* background: rgb(211, 211, 211);   */
    /* background: #6e68af;   */
    background: #BCB4D9; 
  }
  .categories-mobile-p2::-webkit-scrollbar-thumb {
    background-color: #1c1b4b;  
    border-radius: 5px;      
    /* border: 1px solid rgb(123, 123, 123); */
    border: 1px solid #1c1b4b;
  }
  /* .categories-mobile-p2::-webkit-scrollbar-thumb:hover {
    border: 2px solid black;
    background-color: grey;
  } */
  /* .categories-mobile-p2{
    scrollbar-width: thin;
    scrollbar-color: rgb(255, 255, 255) rgb(211, 211, 211);
  } */
  

  .confirmation-m-outer{
    position: fixed;
    top: 150px;
    left: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 1000;
  }
  .confirmation-m-container{
    /* height: 220px; */
    width: 500px;
    /* width: max-content; */
    position: relative;
    background-color: rgb(255, 255, 255);
    /* border: 10px solid green; */
    border-top: 20px solid none;
    border-left: 20px solid none;
    border-right: 20px solid none;
    border-bottom: 20px solid none;
    border-radius: 10px;
    z-index: 10;
    /* display: flex; */
    /* justify-content: space-between; */
    /* align-items: center; */
    /* vertical-align: middle; */

    -webkit-box-shadow: 5px 6px 15px 0px rgba(184,184,184,1);
    -moz-box-shadow: 5px 6px 15px 0px rgba(184,184,184,1);
    box-shadow: 5px 6px 15px 0px rgba(184,184,184,1);
    padding-bottom: 50px;
  }
  .confirmation-m-title{
    font-size: 25px;
    padding: 10px 10px;
    color: rgb(33, 33, 33);
    text-align: center;
    border-bottom: 1px solid #d2d2d2;
  }
  .confirmation-m-info{
    color: gray;
    text-align: center;
    font-size: 18px;
    padding: 10px 15px 20px 15px;
}
.confirmation-m-footer{
    width: 100%;
    height: 50px;
    position: absolute;
    bottom: 0px;


    /* padding-left: 50%; */
    /* width: 50%; */
}
.confirmation-m-button{
    width: 50%;
    height: 100%;
    border: none;
}
.confirmation-m-button-primary{
    border-left: 1px solid rgb(180, 180, 180);
    border-bottom-right-radius: 10px;
}
.confirmation-m-button-secondary{
    /* border-right: 1px solid gray; */
    border-bottom-left-radius: 10px;
}

.OTPModal-m-title{
    font-size: 25px;
    padding: 10px 10px;
    color: rgb(33, 33, 33);
    text-align: center;
    border-bottom: 1px solid rgb(207, 207, 207);
}
.OTPModal-m-body{
    padding: 0px 20px;
    margin-bottom: 20px;
}
.OTPModal-m-input-div{
    margin: 15px 0px 15px 0px;
}
.OTPModal-m-input-label{
    font-size: 18px;
}

.OTPModal-m-input-label{
    display: inline-block;
}
.OTPModal-m-input{
    display: inline-block;
    width: calc(100% - 55px);
    /* width: 200px; */
    margin-left: 20px;
}
.form-control{
    border: 1px solid #838383;
}
.form-control:focus {
    border-color: #1c1b4b;
    box-shadow: 0 0 0 .25rem #1c1b4b47;
}
.lower-btn-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 0 20px; */
}
.Partner-heading1-nui{
    min-height: 10px;
}
.radio-bank-n{
    display: inline-block;
    position: relative;
    top: -33px;
    margin-bottom: 10px;
    font-size: 17px;
    width: 350px;
}
.radio-option-n{
    display: inline-block;
    margin-right: 20px;
    position: relative;
    top: 10px;
}
.radio-label{
    margin-left: 5px;
}
.message-on-loader{
    z-index: 20000 !important;
}
.ddrop-div{
    height: 100%;
    /* background: transparent; */
    text-align: center;
    padding: 10px 0;
    border: 2px dashed gray;
}
.ddrop-icon{
    display: block;
    width: 100%;
    text-align: center;
    height: 50px;   
}
.ddrop-ul{
    padding: 5px 0 0 !important;
    font-size: 14px;
    font-weight: 500;
    list-style-type: none;
    margin: 0px;
}
.ddrop-li{
    padding: 10px 0;
}
.ddrop-li-div{
    padding: 10px 10px;
    border: 1px solid black;
    border-radius: 4px;
    background: lightgrey;
}
.ddrop-p{
    width: 90%;
    display: inline-block;
    /* padding-top: 3px; */
    vertical-align: middle;
    margin: 0px !important;
}
.ddrop-hp{
    display: inline-block;
    padding-left: 10px;
    margin: 0px;
}
.ddrop-del-div{
    display: inline-block;
    width: 10%;
    text-align: right;
}
.mar-0{
    margin: 0px !important
}
.ddrop-span{
    font-size: 12px;
}
.ddrop-a{
    cursor: pointer;
}
/* .option-div-no-selected */
@media (max-width: 780px) {
    .App{
        padding: 20px 20px;
    }

  }
@media (max-width: 768px) {
    .dropdown-div-p2{
        width: 87vw;
    }
    .dropdown-options-p2{
        width: 87vw;
    }
    .dropdown-options-partner-p2{
        width: 87vw;
    }

  }
  @media (max-width: 700px) {
    .scl-radio-n{
        min-width: 270px;
        width: auto !important;
    }
    .radio-option-n{
        top: 0px;
    }
    .radio-bank-n{
        top: 0px;  
    }
  }
  @media (max-width: 1100px){
    .search-container{
        left: 50%;
        margin-left: -135px;
    }
    .Partner-heading1-nui{
        margin-top: 80px;
        margin-bottom: 15px;
    }
    .search-partners-container{
        width: 290px;
    }
    .Partner-heading1-nui-p2{
        margin-top: 80px;
        margin-bottom: 10px;
    }
    /* .search-container-div{
        position: relative;
        margin-bottom: 60px;
    } */
    .search-container-div-p2{
        position: relative;
        margin-bottom: 60px;
    }
    .search-container-p3{
        left: auto;
        right: 0px;
        /* margin-left: -135px; */
    }
    /* .Partner-heading1-nui-p3{
        display: block;
    } */
    .partner-remove-p3{
        right: 20px;
    }
    .partner-remove-p3 span{
        display: none;
    }
    /* .pwc-logo{
        height: 60px;
    } */
    .categories-container{
        margin-top: 80px;
    }
  }
  @media (max-width: 576px){
    .categories-container{
        display: none !important;
    }
    .main-conatiner{
        margin-top: 120px;
    }
    .dropdown-div{
        display: none;
    }
    .dropdown-options{
        position: static;
        display: inline-block !important;
        max-height: none;
        margin-bottom: 20px;
        /* margin-left: 5vw; */
        width: 100%;
        background-color: #ffffff;
    }
    .category {
        height: auto;
        width: 100%;
      }
    .search-container{
        /* top: 100px */
        /* position: static;
        text-align: center;
        margin-bottom: 15px; */
        left: 50%;
        margin-left: -145px;
        /* transform: translate(-50%, 0); */
    }
    .search-container-p3{
        left: 50%;
        top: 0px;
        margin-left: -135px;
    }
    .Partner-heading1-nui{
        margin-top: 80px;
        margin-bottom: 15px;
    }
    .search-partners-container{
        width: 290px;
    }
    .Partner-heading1-nui-p2{
        margin-top: 80px;
        margin-bottom: 10px;
    }
    .search-container-div-p1{
        margin-bottom: 60px;
        position: relative;
    }
    .search-container-div-p2{
        margin-bottom: 60px;
        position: relative;
    }
    .search-container-div-p3{
        margin-bottom: 60px;
        position: relative;
    }
    .categories-container-p2{
        display: none;
    }
    /* .dropdown-options-partner {
        width: 100%;
    } */
    .dropdown-options-partner-p2-m{
        cursor: pointer;
        width: 300px;
    }
    .google-translate{
        top: 20px;
        right: 20px;
    }
    .menu-icon{
        display: inline-block !important;
        position: absolute;
        top: 20px;
        right: 30px;
    }
    .categories-container-p2-mobile{
        display: block;
    }
    .accordion{
        display: none;
    }
    .Partner-heading1-nui-p3{
        display: block;
    }
    .title-p{
        margin-top: 0px;
    }
    .title-p-p1{
        margin-top: 100px;
    }
    .info-inner-p{
        margin-left: 0px;
    }
    .info-p-header{
        display: none;
    }
    .back-btn-p{
        display: none;
    }
    .next-btn{
        width: 150px;
    }
    .back-btn-p-m{
        margin-top: 10px;
        display: block;
        width: 100%;
    }
    .input-p{
        width: 100%;
        max-width: 350px;
    }
    .info-p{
        margin-bottom: 100px;
    }
    .btn-div-p{
        position: absolute;
    }
    .flash-message-outer{
        padding: 0px 50px;
    }
    .flash-message-container{
        padding-right: 75px;
        padding-left: 50px;
        /* min-width: 374px; */
        min-height: 100px;
        max-width: 90vw;
    }
    .flash-message-success-icon{
        margin: 0px 10px;
    }
    .flash-message-close{
        padding: 26px 10px;
    }
    .flash-message-icon-div{
        position: absolute;
        left: 0px;
        top: 50%;
        margin-top: -15px;
    }
    .pwc-logo{
        height: 40px;
        left: 20px;
        top: 20px;

        max-width: 80px;
        max-height: 40px;
        height: auto;
    }
    .btn {
        font-weight: 400;
        line-height: 1.5;
        padding: .175rem .75rem;
        font-size: 1rem;
      }
      .back-btn-pp{
        font-size: 17px;
    }
    .categories-container{
        margin-top: 80px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding: 0px 20px;
    }
    .dropdown{
        width: 100%;
    }
    .dropdown-options-partner{
        width: 100%;
        cursor: pointer;
    }
    .dropdown-options-p2-mobile{
        width: 100%;
    }
    .option-div-mobile{
        padding: 0px 20px;
    }
    .confirmation-m-container{
        margin: 0px 50px;
        width: 100%;
        padding-bottom: 40px;
        max-width: 350px;
    }
    .confirmation-m-title{
        padding: 5px 10px;
    }
    .confirmation-m-info{
        padding: 5px 15px 15px 15px;
    }
    .confirmation-m-footer{
        height: 40px;
    }

    .OTPModal-m-input-div{
        /* display: flex; */
        padding: 5px 10px;
    }
    .OTPModal-m-title{
        padding: 5px 10px;
        font-size: 22px;
    }
    .OTPModal-m-body {
        margin-bottom: 15px;
      }
    .OTPModal-m-input-label{
        display: inline-block;
    }
    .OTPModal-m-input{
        display: inline-block;
        width: calc(100% - 45px);
        /* width: 200px; */
        margin-left: 10px;
    }
    #root{
        background-position: center top, 50% 80%;
    }
    .lower-btn-div {
        position: fixed;
        padding: 0 20px;
        width: 100%;
        bottom: 50px;
        left: 0;
        position: absolute;
    }
    .success-page{
        padding: 0px 20px;
    }
    .Partner-class-nui{
        margin-bottom: 90px;
    }
    .scl-blibli{
        width: 270px;
    }
  }
