.modal-new {
  position: fixed;
  z-index: 1;
  padding-top: 50px; 
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  display:block;
}

/* Modal Content */
.modal-content-new {
  background-color: #fefefe;
  margin: auto;
  padding: 15px;
  border: 1px solid #888;
  width: 80%;
  max-width: 442px;
  max-height: 80%; 
  overflow-y: auto; 
  border-radius: 15px;
}

.close-new {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  top: -20px;
  right: -5px;
}

.close-new:hover,
.close-new:focus {
  color: #000;
  text-decoration: none;
}


.head-name {
  text-align:start;
  margin-bottom: 20px;
  margin-right: 40px;
}

.head-name h4 {
  font-size: 20px;
  margin-bottom: 10px;
}

.head-name h5 {
  font-size: 16px;
  margin: 0;
}

.close-data{
  text-align: center;
  padding-top: 5px;
}


.download-excel-modal {
  width: fit-content;
  min-width: 100px;
  margin-top: 50px;
  margin-left: 150px;
}

.export-csv-btn {
  background-color: #1c1b4b;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0px 10px;
  min-width: max-content;
}

.fade-btn{
  pointer-events: none;
  background-color: #606485;
}

.error-modal-content {
  height: 100px;
}

.showtable {
  margin: auto;
  width: 100%;
  border-collapse: separate; 
  border-spacing: 10px;
}

.showtable th {
  text-align: center;
  border: none !important;
  padding: 8px;
}

.showtable td {
  text-align: center;
  border: none !important;
  padding: 8px;
}

.showtable td:first-child {
  max-width: 200px; 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


/* media query for mobile devices with a maximum width of 768px */
@media (max-width: 768px) {
  .modal-new {
    width: 100%;
    margin: 0 auto;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow-y: auto;
    z-index: 9999;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .modal-content-new {
    padding: 15px;
    position: relative;
    z-index: 2;
    border-radius: 15px;
  }


  .close-new {
    font-size: 20px;
    position: relative;
    top: -15px;
  }

  .head-name{
    margin-right: 50px;
  }
  .head-name h4 {
    font-size: 16px;
  }

  .head-name h5 {
    font-size: 14px;
  }

  .showtable th {
    font-size: 12px;
  }

  .showtable td {
    font-size: 12px;
  }

  .showtable {
    width: 100%;
    table-layout: fixed;
  }

  .export-csv-btn {
    font-size: 14px;
    padding: 8px 12px;
    margin:auto;
  }

  .close-data {
    display: block;
    text-align: center;
    font-size: 16px;
    padding-top: 3px;
  }
  .fade-btn{
    pointer-events: none;
    background-color: #606485;
  }
}
