@media (max-width: 1421px) and (min-width: 940px) {
    .scl {
      width: 350px !important;
      text-align: left !important;
    }
  }

  @media (max-width: 577px) {
    .scl {
      width: 350px !important;
      text-align: left !important;
    }
  }

  .scl {
    width: 318px;
    text-align: left !important;
  }

  .p-dropdown{
    border: 1px solid #838383;
  }
  .p-dropdown-items-wrapper{
    border: 1px solid #838383;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.249) !important;
    z-index: 1;
    max-height: 252px !important;

  }

.p-dropdown-items-wrapper::-webkit-scrollbar {
  width: 8px; 
}

.p-dropdown-items-wrapper::-webkit-scrollbar-track {
  background-color: #f1f1f1; 
}

.p-dropdown-items-wrapper::-webkit-scrollbar-thumb {
  background-color: #888; 
  border-radius: 10px;
}

.p-dropdown-items-wrapper::-webkit-scrollbar-thumb:hover {
  background-color: #555; 
}

  .verification-message{
    text-align: center;
    bottom: 6.3rem;
    width: 100%;
    left: 50%;
    position: absolute;
    font-size: 1rem;
    transform: translateX(-50%);
  }

  .p-disabled{
    background-color: #e9ecef;
    opacity: 1 !important;
    color: #212529 !important;
    border: 1px solid #838383;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    /* display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box; */
  }

  .p-disabled .p-inputtext{
    color: #212529 !important;
  }

  .checkBoxItem{
    display: flex;
    gap: 6px;
  }

  .p-button.p-button-icon-only {
    width: 3rem !important;
    padding: 1px 0px 0px 3px !important
  }
