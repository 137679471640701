.refresh-btn{
    line-height: 2 !important;
    color: #ffffff !important;
    background-color: #1c1b4b ;
}

.refresh-btn:hover{
    color: #ffffff !important;
    background-color: #1c1b4b ;
    opacity: 0.9 !important;
}

.p-button:disabled{
    color: #ffffff !important;
    background-color: #1c1b4b ;
    opacity: 0.6 !important;
}

.p-button.p-button-outlined {
    background-color: transparent !important;
    color: #1c1b4b !important;
    border: 1px solid;
}

.scl-gojek{
    margin-top: 6px !important;
    width: 100%;
}