.footer{
    position: absolute;
    bottom: 0.9rem;
    /* right: 0.9rem; */
    left: 50%;
    transform: translateX(-50%);
}

.powerb{
    font-size: 0.9rem;
    margin-right: 0.3rem;
}