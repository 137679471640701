.reference-text-2{
    font-size: 18px;
    font-weight: normal;
    margin: 10px 0px 0px 0px;
}
.padb_7{
    margin: 10px 0px;
}
.refernce-image{
    max-width: 100%;
    padding: 0px;
    border: 1px solid #8080807d;
    margin: 10px 0px 0px 0px;
}
.reference-main-heading{
    margin-top: 10px;
}