.App {
  text-align: left;
  padding: 20px 20px;

  position: relative;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* styling loader */
.fp-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #f8f8f8ad;
}



.nav_btn {
  width: 100%;
  line-height: 2;
  color: #ffffff;
  background-color: #1c1b4b;
}

.nav_btn:hover {
  color: #ffffff;
  background-color: #6e68af;
}
.selection-page{
  padding: 150px 0px;
  text-align: center;
}
.btn-container{
  margin-top: 50px;
  display: flex;
}
.middle-word{
  margin: 0px 10px;
  font-size: 20px;
}
#submit-root-pwc{
  margin-top: 20px;
}
#widget-root{
  position: relative;
}
.back-btn{
  margin-top: 20px;
}

.fp-container .fp-loader {
  top: 45%;
  left: 45.5%;
  z-index: 1000;
  position: absolute;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 576px) { 
  .App {
    text-align: left;
    padding: 20px 40px;
  }
  .middle-word{
    margin: 0px 30px;
  }
  .back-btn{
    margin-top: 0px;
    position: absolute; 
    bottom: 0px; 
    right: 0px;
    width: 46% !important;
    margin-bottom: 20px;
    margin-right: 15px;
  }
}


@media (min-width: 768px) { 
  .App {
    text-align: left;
    padding: 20px 80px;
  }
  .home-btn{
    max-width: 250px;
  }
  .back-btn{
    width: 33% !important;
  }
  .btn-container{
    justify-content: center;
  }
}
@media (min-width: 992px){
  .back-btn{
    width: 25% !important;
  }
}

.nav-btn-pwc {
  width: 100%;
  line-height: 2 !important;
  color: #ffffff !important;
  background-color: #1c1b4b !important;
}
.nav_btn:hover, .nav-btn-pwc:hover {
  color: #ffffff !important;
  background-color: #1c1b4b !important;
  opacity: 0.7;
}
.nav_btn:focus, .nav-btn-pwc:focus{
  box-shadow: 0 0 0 .25rem #9a99d8 !important;
}



/* .form-group-pn {
  border: 1px solid #ced4da;
  padding: 0px;
  border-radius: 6px;
  width: auto;
} */
/* .form-group-pn:focus-within {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
  
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13,110,253,.25);
  
} */
.form-group-pn input {
  /* display: inline-block;
  width: auto;
  border: none; */
  /* width: calc(100% - 45px); */
  padding-left: 55px;
}
/* .form-group-pn input:focus {
  box-shadow: none;
} */
/* .form-group-pn span{
  width: 45px;
  display: inline-block;
  padding-right: 10px !important;
  padding-left: 10px !important;  
} */
.country-code{
  position: absolute;
  margin-top: 7px;
  font-size: 16px;
  padding-right: 10px !important;
  padding-left: 10px !important; 
}