.pad_0 {
    padding: 0px;
}

.mar_0 {
    margin: 0px;
}

.pad_15 {
    padding: 15px;
}

.pad_30 {
    padding: 30px;
}

.padl_16 {
    padding-left: 16px;
}

.padt_15 {
    padding-top: 15px;
}

.padt_10 {
    padding-top: 10px;
}

.padr_15 {
    padding-right: 15px;
}

.padr_10 {
    padding-right: 10px;
}

.padb_7 {
    padding-bottom: 7px
}

.kh_div {
    background: #F2F5F6;    
}

.khc_div {
    padding: 0px;
    background: #FFF;
}

.siimg {
    padding: 0 0 15px 0;
}

.heading1 {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 40px;
    color: #073447;
}

.sp_title {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #1E2123;
}

.dpc_title {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    padding-top: 15px;
}

.cards {
    border: 1px solid rgb(158, 63, 253);
    border-radius: 8px;
    padding: 20px;
    cursor: pointer;
}

.cards_name {
    display: inline-block;
    padding-left: 10px;
}

.nav_btn {
    width: 100%;
    line-height: 2;
    color: #ffffff;
    background-color: #1c1b4b;
}

.nav_btn:hover {
    color: #ffffff;
    background-color: #6e68af;
}

.hlink {
    color: #1c1b4b !important;
}

.hlink.active, .hlink:hover {
    color: #6e68af !important;
}

.form_inp_div {
    padding: 0 0 15px 0;
}

.scl {
    padding-bottom: 5px;
    font-weight: 500;
    vertical-align: top;
}

.scb {
    padding: 0px 15px;
}

.gsp {
    padding: 20px 15px;
}

.padb_15 {
    padding-bottom: 15px;
}

.accordion-body {
    overflow-x: overlay;
}

.text-center {
    text-align: center;
}

.resmodal .modal-dialog {
	color: #636363;
	width: 325px;
    /* margin-top: 25vh; */
    margin: 25vh auto 1.75rem;
}
.resmodal .modal-dialog .modal-content {
	padding: 20px;
	border-radius: 5px;
	border: none;
}
.resmodal .modal-dialog .modal-header {
	border-bottom: none;   
	position: relative;
    padding: 15px 15px 5px;
}
.resmodal .modal-dialog .modal-title {
	text-align: center;
	margin: 20px 0 0px;
}	
.resmodal .modal-dialog .icon-box {
	color: #fff;		
	position: absolute;
	margin: 0 auto;
	left: 0;
	right: 0;
	top: -70px;
	width: 95px;
	height: 95px;
	border-radius: 50%;
	z-index: 9;
	padding: 15px;
	text-align: center;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}
.resmodal .modal-body {
    padding-top: 0;    
}
.mbody_text {
    font-size: 1rem;
    color: #636363;
    font-weight: normal;
}

.success_backcolor {
	background: #82ce34;
}

.danger_backcolor {
	background: #ef513a;
}

.cb_confirm {
    display: inline-block;
    font-size: 1rem;
    font-weight: lighter;
}

.ibv {
    display: inline-block;
}

/* Check box styling */
.form-check-input {
    height: 1.5rem;
    width: 1.5rem;
}

.width_100 {
    width: 100%;
}

.bdm_link {
    display: inline-block;
    cursor: pointer;
    color: #0d6efd;
    font-weight: lighter;
}

.bdm_link:hover {
    color: blue;
}

.bsar_list > li {
    font-weight: 500;
}

.fw_500 {
    font-weight: 500;
}

.modal-dialog {
    margin: 5rem auto 1.75rem;
}

.note_msg {
    color: red;
    font-size: 12px;
}

.a_master_cb {
 text-decoration: none;
}

.ldesc_msg {
    color: gray;
    font-size: 12px;
    margin-bottom: 0;
    padding-bottom: 5px;
}

.divider {
    margin-top: 40px;
}