.dialog-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
  }
  
  .spinner {
    position: absolute !important;
}
  
  
  .p-dialog .p-dialog-header-icon:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  
.p-dialog-content{
  text-align: center;
}

p{
  font-size: initial;
}

@media (max-width: 350px) {
  .pi-camera{
    font-size: 1.5rem;
  }
}