@media (max-width: 1421px) and (min-width: 940px) {
    .scl {
      width: 350px !important;
      text-align: left !important;
    }
  }

  @media (max-width: 577px) {
    .scl {
      width: 350px !important;
      text-align: left !important;
    }
  }


  .p-dropdown:not(.p-disabled).p-focus{
    border-color: #1c1b4b !important;
    box-shadow: 0 0 0 0.25rem #1c1b4b47 !important;
  }

  .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus{
    outline: none !important;
    box-shadow: none !important;
  }

  .scl {
    width: 318px;
    text-align: left !important;
  }

  .p-dropdown{
    border: 1px solid #838383;
  }
  .p-dropdown-items-wrapper{
    border: 1px solid #838383;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.249) !important;
    z-index: 1;
    max-height: 252px !important;

  }

.p-dropdown-items-wrapper::-webkit-scrollbar {
  width: 8px; 
}

.p-dropdown-items-wrapper::-webkit-scrollbar-track {
  background-color: #f1f1f1; 
}

.p-dropdown-items-wrapper::-webkit-scrollbar-thumb {
  background-color: #888; 
  border-radius: 10px;
}

.p-dropdown-items-wrapper::-webkit-scrollbar-thumb:hover {
  background-color: #555; 
}

  .verification-message{
    text-align: center;
    bottom: 6.3rem;
    width: 100%;
    left: 50%;
    position: absolute;
    font-size: 1rem;
    transform: translateX(-50%);
  }
/* Common styles for all screen sizes */
.p-fileupload-buttonbar {
  display: flex;
  flex-direction: row;
  justify-content: left; /* Center buttons in the container */
  align-items: left;
  border-radius: 3px;
  border: 0.1px solid transparent !important;
  background-color: #b8b4ffb0 !important;
}

/* Styles specific to smaller screens, where buttons will stack vertically */
@media (max-width: 767px) {
  .p-fileupload-buttonbar {
    flex-direction: column;
  }
  
  .p-fileupload-buttonbar button {
    width: 100%; /* Make buttons take the full width */
    /* margin-bottom: 0.5rem; Add space between buttons */
  }
}

/* Styles for larger screens, where buttons are displayed in a row */
@media (min-width: 768px) {
  .p-fileupload-buttonbar button {
    width: auto; /* Allow buttons to size according to content */
    max-width: 200px; /* Limit the maximum width of buttons */
    /* margin-right: 0.5rem; Add space between buttons */
  }
}

