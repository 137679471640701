:root {
    --d-body-fc: #171642;
    --d-body-ffm: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

    --d-r-bgi: url('../components/images/images/home-background.png'), url("../components/images/images/background-gradient-1.png");
    --d-r-bgc: 'none';
    --d-r-bgs: auto 100vh, cover;

    --d-partner-container-bgc: #ffffff00;
    --d-partner-container-fc: #171642;
    --d-partner-container-partner-bgc: #ffffff00;

    --d-partner-input-bgc: #736aae1f;
    --d-partner-input-fc: #171642;


    --body-fc: #171642;
    --body-ffm: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

    --r-bgi: url('../components/images/images/home-background.png'), url("../components/images/images/background-gradient-1.png");
    --r-bgc: 'none';
    --r-bgs: auto 100vh, cover;

    --partner-container-bgc: #ffffff00;
    --partner-container-fc: #171642;
    --partner-container-partner-bgc: #ffffff00;

    --partner-input-bgc: #736aae1f;
    --partner-input-fc: #171642;

  }
.App{
    height: 100vh;
    padding: 20px 40px;
    /* font-family: 'Helvetica' !important; */
    /* font-family: -apple-system, BlinkMacSystemFont, sans-serif !important; */
    /* font-family: 'Dosis', sans-serif; */
    /* font-family: 'ubuntu'; */
    /* font-family: 'Axiforma', 'Helvetica', 'Metrophobic', sans-serif !important; */
    /* color: #171642; */

    font-size: 20px;
    /* font-weight: 500; */

    /* background-color: #f7f7f7; */
}
body{
    /* font-family: 'ubuntu'; */
    color: var(--body-fc);
    font-family: var(--body-ffm);
}
.partner-page-n{
    width: 45%;
    display: inline-block;
    position: absolute;
    padding: 120px 20px 20px 20px;
    height: calc(100vh - 40px);
    overflow: scroll;

    margin: 120px 0px 20px 20px;
    padding: 0px 20px 0px 20px;
    height: calc(100vh - 180px);
    width: calc(45% - 20px);

    margin: 185px 0px 20px 20px;
    height: calc(100vh - 245px);

    margin: 165px 0px 20px 20px;
    height: calc(100vh - 225px);

    margin: 100px 0px 20px 20px;
    height: calc(100vh - 160px);

    width: calc(57% - 25px);

    height: calc(100vh - 200px);

    /* margin: 185px 0px 20px 55%;
    position: relative;

    width: calc(45% - 20px) !important; */
    /* display: flex !important;
    flex-direction: column;
    justify-content: space-between;

    
    width: calc(45% - 20px) !important; */

    border-left: 2px dashed gray;
    /* padding: 0px 0px 0px 20px;
    margin: 120px 20px 20px 20px; */
    /* width: 45%; */

    /* background: rgb(238,174,202);
    background: radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%); 

    background: rgb(216,216,243);
background: radial-gradient(circle, rgba(216,216,243,1) 0%, rgba(229,231,246,1) 100%); */


    /* background:-moz-linear-gradient(38deg, rgba(253, 254, 253, 1) 1%, rgba(223, 219, 242, 1) 50%, rgba(193, 202, 246, 1) 100%); 
    background:-webkit-linear-gradient(38deg, rgba(253, 254, 253, 1) 1%, rgba(223, 219, 242, 1) 50%, rgba(193, 202, 246, 1) 100%);
    background:-o-linear-gradient(38deg, rgba(253, 254, 253, 1) 1%, rgba(223, 219, 242, 1) 50%, rgba(193, 202, 246, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FDFEFD', endColorstr='#C1CAF6', GradientType=1 );
    background:-ms-linear-gradient(38deg, rgba(253, 254, 253, 1) 1%, rgba(223, 219, 242, 1) 50%, rgba(193, 202, 246, 1) 100%);
    background:linear-gradient(38deg, rgba(253, 254, 253, 1) 1%, rgba(223, 219, 242, 1) 50%, rgba(193, 202, 246, 1) 100%); */

    border-left: 2px dashed rgba(128, 128, 128, 0);
    padding: 10px 20px;
    box-shadow: 8px 2px 15px -3px rgba(0,0,0,0.75);
    width: calc(57% - 55px);
    margin-left: 0px;

    border-radius: 0px 5px 5px 0px;
    /* width: calc(57% - 105px); */

    /* border: 1px solid #808080a1; */
    /* background-color: #736aae1f; */
    box-shadow: 11px 2px 15px -3px rgba(117,132,168,1);

    margin: 80px 0px 20px 0px;

    width: calc(57% - 45px);
    
    background-color: var(--partner-input-bgc);
    color: var(--partner-input-fc);
}
.partners-list-n{
    width: 43%;
    margin: 120px 0px 60px 0px;
    display: inline-block;

    margin: 100px 0px 20px 0px;

    padding: 10px 20px;
    box-shadow: -6px 2px 15px -3px rgba(0,0,0,0.75);

    border-radius: 5px 0px 0px 5px;
    /* border-right: 2px dashed gray; */
    /* margin-right: 10px; */


    box-shadow: -5px 2px 15px -3px rgba(117,132,168,1);

    margin: 80px 0px 20px 0px;


    padding: 10px 0px 10px 20px;

    background-color: var(--partner-container-bgc);
    color: var(--partner-container-fc);
}
.partners-list-heading-n{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 30px;
    height: 30px;
}
.country-code-n{
    margin-top: 4px;
}
.partners-list-partners-n{
    /* display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
    gap: 50px 10px; */
    /* overflow: scroll;
    height: calc(100vh - 240px); */
    padding: 20px 10px 10px 10px;

    display: grid;
    grid-template-columns: repeat(auto-fill, 250px);
    justify-content: space-around;
    justify-content: flex-start;
    align-content: flex-start;
    grid-gap: 60px 10px;

    grid-gap: 40px 10px;

    grid-gap: 40px 30px;
    
    grid-gap: 40px 20px;

    grid-template-columns: repeat(auto-fill, 220px);

    grid-template-columns: repeat(auto-fill, 200px);

    grid-template-columns: repeat(auto-fill, 180px);

    grid-template-columns: repeat(auto-fill, 100px);

    grid-template-columns: repeat(auto-fill, 180px);

    grid-template-columns: repeat(auto-fill, 120px);

    padding: 20px 20px 10px 10px;
    /* height: calc(100vh - 280px); */
}
.partner-container-n{
    width: 250px;
    border: 1px solid #0006;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 5px 10px 8px;
    cursor: pointer;
    position: relative;

    width: 220px;
    height: 54px;
    padding: 5px 5px 10px 2px;

    padding: 0px;


    width: 200px;
    height: 120px;

    width: 180px;

    width: 100px;
    height: 90px;

    width: 180px;
    height: 90px;

    height: 80px;

    width: 120px;
    height: 60px;

    /* width: 200px; */

    justify-content: center;

    background-color: var(--partner-container-partner-bgc);
}
.partner-container-n-showing{
    outline: 3px solid rgba(0, 0, 255, 0.47);
    border: 1px solid rgba(0, 0, 255, 0.745);
}
.partner-container-n-selected{
    /* outline: 2px solid green;
    border: 1px solid green; */

    outline: 2px solid #2fad4d;
    border: 1px solid #2fad4d;
    
}
.partner-container-n-selected:hover{
    cursor: default;
}
.partner-category-n{
    padding: 0px 15px;
    /* border: 1px solid gray;
    background-color: rgba(201, 201, 201, 0.488); */
    border-radius: 3px;
    position: absolute;
    /* left: 0px; */
    bottom: -30px;
    cursor: default;
    font-size: 15px;


    
    bottom: 0px;
    font-size: 11px;
    font-style: italic;
    right: 0px;
}
.partner-n-top-heading{
    font-size: 22px;
    font-weight: bold;
    position: fixed;
    top: 100px;
    left: 0;
    width: 100%;
    text-align: center;
}
/* .partner-div-n{
    display: inline-block;
} */
.partner-image-n {
    /* height: 30px;
    margin-left: 8px;
    margin-right: 10px;

    height: 23px;
    margin-left: 8px;
    margin-right: 4px; */


    height: 80px;

    height: 40px;

    height: auto;
    width: 150px;
    width: 135px;
    width: 100px;
  }
  /* .partner-container-n-selected .partner-image-n{
    position: relative;
    left: -10px;
  } */
  .svg-selected-n{
      display: none;
  }
.btn-div-p-n{
    position: absolute;
    bottom: 10px;
    /* right: 20px; */

    /* position: relative; */
    /* top: -40px */
    /* margin-top: 30px; */
}
.category-toogle-n{
    display: flex;
    /* justify-content: space-between; */
    /* margin-bottom: 10px; */
    gap: 0px 10px;
    /* width: max-content; */
    border-bottom: 5px solid #7167ab ;
}
.category-toogle-value-n{
    padding: 10px 10px;
    border: 1px solid #7167ab;
    border-bottom: 1px solid #7167ab00 ;
    cursor: pointer;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    width: 50%;
    text-align: center;
    font-weight: bold;
}
/* .category-toogle-value-n:last-child{
    border-right: 1px solid rgba(128, 128, 128, 0) ;
} */
.category-toogle-value-selected-n{
    background-color: #7167ab;
    color: white;
}
.partner-page-n-connected{
    /* font-size: 25px; */
    /* margin-left: 100px; */
    /* padding-top: 100px; */

    font-size: 18px;
    /* margin-left: 100px;
    padding-top: 165px; 
    margin-top: 300px; */
    /* position: relative;
    top: 205px; */
    /* text-align: left; */
    margin-top: 50px;
}
.partner-info-icon-message{
    color: rgb(42, 42, 42);
}
.back-btn-p-m{
    background-color: #fff0 !important;
    color: black !important;
    width: 80px !important;
}
.svg-selected-n{
    width: 15px;
    height: 15px;
}
.note_msg-n{
    font-size: 15px;
}
.partner-info-icon-n{
    bottom: 32px !important;
}
.beta-div-n{
    top: -32px !important;
}
.radio-bank-n{
    display: inline-block;
    position: relative;
    top: 0px !important;
    margin-bottom: 10px;
    font-size: 17px;
    width: 350px;
}
.scl-radio-n{
    vertical-align: top;
}
.title-p-n, .partners-list-heading-n{
    font-size: 20px;
    font-weight: 600;

    font-size: 23px;
    font-weight: 600;
    /* text-align: center; */
}
.title-p-n{
    text-align: left !important;
}
.btn-n{
    width: 150px !important;
    border-radius: 7px;
}
.info-inner-p {
    margin-left: 0px !important;
  }
.title-p {
    text-align: left;
}
.selected-icon-n{
    height: 20px;
    width: 20px;
    position: absolute;
    top: -12px;
    /* left: -12px; */
    left: 167px;

    top: -9px;
    left: -9px;

    height: 18px;
    width: 18px;
}
.remove-icon-n{
    position: absolute;
    top: 90px;
    left: 75px;

    top: 25px;
    left: 170px;  
    left: 150px; 

    top: -8px;
    left: 109px;
    height: 18px;
    width: 18px;
    cursor: pointer; 
}
.form-control {
    border: 1px solid #838383;
    height: 32px;
    /* font-size: 15px; */
  }
/* .radio-option-n{
    display: inline-block;
} */
.resend-btn-p-m{
    width: 145px !important;
    text-align: left;
}
.btn-select-file {
    border: none;
    text-align: center;
    background-color: rgb(218, 216, 216);
    height: 50px;
    border-radius: 12px;
    color: black;
    font-weight: bold;
    transition-duration: 0.6s;
}  
.btn-select-file:hover {
    background-color: rgba(0, 0, 255, 0.26);
    color: aliceblue;
  }
  .input-f-p{
    width: 100%;
    text-align: center;
    padding: 20px;
    border: 2px #8e8e8e dashed;
    /* width: 60%; */
    margin: auto;
  }
#root{
    min-height: 100vh;
    background-image:  url('../components/images/images/home-background.png'), url("../components/images/images/background-gradient-1.png");
    /* background-position: left top, 50% 80% !important; */
    background-size: auto 100vh, cover;
    background-repeat: repeat-y, no-repeat !important;
    background-attachment: fixed, fixed !important;

    /* background-image: url('../components/images/images/home-background.png');
    background-position: right top;
    background-repeat:repeat-y;
    background-size: auto;
    background-attachment: scroll; */
    /* background-color: white; */
    /* background-repeat: space; */
    /* background-size: 100% auto;
    background-size: auto 100vh; */
    /* background-size: contain; */

    /* -webkit-transform: scaleX(-1); */
    /* transform: scaleX(-1); */
    /* filter: invert(10%); */
    background-image:  var(--r-bgi) !important;
    background-color: var(--r-bgc) !important;
    background-size: var(--r-bgs) !important;
  }
  .categories-container, .Partner-class-nui, .lower-btn-div{
    display: none !important;
}
.back-btn-pp-n{
    display: none;
}
.info-p-n{
    margin-bottom: 0px !important;
    padding-bottom: 100px !important;
}
.partner-page-form-n{
    position: relative;
    min-height: 100%;
}
/* .input-p {
    width: auto !important;
    max-width: 350px;
} */
.partner-list-cont{
    height: calc(100vh - 280px);
    overflow: scroll;
    height: calc(100vh - 220px);
    overflow-x: hidden;
    height: calc(100vh - 260px);

    height: calc(100vh - 280px);
}
.tokopedia-prefix{
    position: absolute;
    margin-top: 5px !important;
    font-size: 15px;
    padding-right: 10px !important;
    padding-left: 10px !important;
}
.prefixT input{
    padding-left: 210px;
}

.stepper-wrapper {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .stepper-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items:  flex-start;
    flex: 1;
  
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
  
  .stepper-item::before {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: -100%;
    z-index: 2;
  }
  
  .stepper-item::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: 0%;
    z-index: 2;
  }
  
  .stepper-item .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #ccc;
    margin-bottom: 6px;
  }
  
  .stepper-item.active {
    font-weight: bold;
  }

  .stepper-item.active .step-counter {
    /* background-color: #4bb543; */
    background-color: #2fad4d;
  }
  
  .stepper-item.completed .step-counter {
    background-color: #2fad4d;
  }
  
  
  .stepper-item.completed::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #2fad4d;
    width: 100%;
    top: 20px;
    left: 0%;
    z-index: 3;
  }
  
  .stepper-item:first-child::before {
    content: none;
  }
  .stepper-item:last-child::after {
    content: none;
  }

  .step-name{
    font-size: 15px;
  }

  .step-complete-image{
    height: 40px;
    width: 40px;
    z-index: 10;
    top: 0px;
    left: 0px;
    position: absolute;
    display: none;
  }

  .stepper-item.completed .step-complete-image{
    display: inline-block;
  }

  .categeory-conatiner{
      margin-bottom: 20px;
  }
    .App{
        overflow: hidden;
    }
    .lower-btn-div-d{
        display: block;
        position: fixed;
        bottom: 0px;
        left: 0px;
        display: flex !important;
        width: 100%;
        padding: 30px 40px;
        align-items: flex-end;
    }
    /* .btn-next-partner-n{
        background-color: #1c1b4b00 !important;
        color: #1c1b4b !important;
        border: 1px solid #1c1b4b;
        font-weight: bold;
    }
    .btn-next-partner-n:hover{
        background-color: #1c1b4b00 !important;
        color: #1c1b4b !important;
    } */
    .btn-next-partner-n{
        border-radius: 5px;
    }
    .btn-sumbit-split-ui{
        background-color: #1c1b4b00 !important;
        color: #1c1b4b !important;
        border: 1px solid #1c1b4b;
        font-weight: bold;
    }
    .btn-sumbit-split-ui:hover{
        background-color: #1c1b4b00 !important;
        color: #1c1b4b !important;
    }
    .info-p-n-m{
        margin-top: 116px !important;
    }
    .message-on-loader{
        z-index: 20000 !important;
    }
    .back-btn-pp-n2{
        text-decoration: none !important;
        font-size: 1rem !important;
        font-weight: normal !important;
    }
    .pwc-logo{
        left: 40px !important;
    }
    .google-translate{
        right: 40px !important;
    }
    .radio-option-n{
        display: inline-block;
        margin-right: 20px;
        position: relative;
        top: 10px;
    }
    .radio-label{
        margin-left: 5px;
    }
    .note_msg-ocr-n{
        color: black;
        margin-top: 10px;
    }
    @media (max-width: 1239px) {
        .scl-radio-n{
            min-width: 270px;
            width: auto !important;
        }
        .radio-option-n{
            top: 0px;
        }
      }
    @media (max-width: 900px) {
        .next-btn{
            width: 170px !important;
        }
      }
  @media (max-width: 576px){
    .App{
        height: auto;
        min-height: 100vh;
        padding: 20px;
        position: relative;
        overflow: auto;
      }
    .partners-list-n{
        display: none;
    }
    .Partner-class-nui, .lower-btn-div{
        display: block !important;
    }
    .lower-btn-div-n{
        display: flex !important;
        justify-content: space-between !important;
        align-items: center !important;
        padding: 0 20px !important;
    }
    .partner-page-n {
        display: block !important;

        position: static !important;
        padding: 5px !important;
        overflow: auto;

        margin: 60px 0px 0px 0px !important;
        height: auto;
        width: auto;
        border-left: none;

        background-color: #736aae00;
        box-shadow: none;
        border-radius: 0px 5px 5px 0px;
    }
    .title-p-p1 {
        margin-top: 30px !important;
    }
    .back-btn-pp-n{
        display: block;
    }
    .btn-div-p-n{
        display: flex;
        justify-content: space-between;
        position: absolute;
        bottom: 50px;
        width: 90% !important;
        align-items: center;
        top: auto;
    }
    .lower-btn-div{
        width: 90% !important;
        left: 25px !important;
        padding: 0px !important;
    }
    .info-p-n{
        margin-bottom: 100px !important;
        padding-bottom: 0px !important;
    }
    .partner-page-form-n{
        position: static;
        height: auto;
        min-height: auto;
    }
    .back-btn-p-m{
        margin-top: 0px !important;
        padding-left: 5px !important;
        width: 85px !important;
    }
    .info-p-n-m{
        margin-top: 20px !important;
    }
    .btn-next-partner-n{
        background-color: #1c1b4b !important;
        color: #ffffff !important;
        border: 1px solid #ffffff00;
        font-weight: normal;
    }
    .btn-next-partner-n:hover{
        background-color: #1c1b4b !important;
        color: #ffffff !important;
    }
    .pwc-logo{
        left: 20px !important;
    }
    .google-translate{
        right: 20px !important;
    }
    .back-btn-pp{
        text-decoration: none !important;
        font-size: 1rem !important;
    }
    .next-btn{
        width: 125px !important;
    }
}